<template>
  <div class="aj-page">
    <HeaderAnnualMeeting/>
    <div style="height: 400px;">
      <HeaderAJ/>
    </div>

    <!-- <div class="wid1200">
        <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
    </div> -->
    <div class="bg1">
      <div class="citation wid1200">
        <h5 class="com-title1">
          <span>中文社会科学引文索引（CSSCI）集刊导览</span>
        </h5>

        <div class="swiper-book">
          <div class="card-carousel-wrapper">
            <div class="card-carousel--nav__left" @click="moveCarousel(-1)" :disabled="atHeadOfList"></div>
            <div class="card-carousel">
              <div class="card-carousel--overflow-container">
                <div class="card-carousel-cards"
                     :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}">
                  <div class="card-carousel--card" v-for="(item,index) in dlData" :key="index">
                    <!-- <router-link :to="{path:'/c/c', query:{id:item.id}}" :title="item.name">
                      <img :src="item.imgPath +'?w=153&h=222'" v-if="item.imgPath" alt="" srcset="">

                    </router-link> -->
                    <a @click="goBook(item)">
                      <img v-if="item.imgPath" :src="item.imgPath" alt=""/>
                      <img v-else-if="item.img" :src="item.img" alt=""/>
                      <img v-else alt="">
                      <!-- <p v-if="item.isInner==0">
                          {{item.name}}
                      </p> -->

                      <!-- <p class="title-spell">{{ item.name }}</p>-->
                      <a class="title-spell" :title="item.name">{{ item.name }}</a>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-carousel--nav__right" @click="moveCarousel(1)" :disabled="atEndOfList"></div>
          </div>
        </div>
        <!-- <ul class="com-book">
          <li v-for="(item, index) in dlData" :key="index" @click="goBook(item)">
            <img v-if="item.imgPath" :src="item.imgPath" alt=""/>
            <img v-else-if="item.img" :src="item.img" alt=""/>
            <img v-else alt="" />

            <p>{{ item.name }}</p>
          </li>

        </ul> -->

      </div>
    </div>

    <div class="bg2">
      <div class="subject wid1200">
        <div class="com-title2">
          <h5>学科导览</h5>
          <div>
            <ul>
              <li :class="[activeXK==item.id? 'active' : '']" v-for="(item, ind) in xkFilter" :key="ind"
                  @click="switchXK(item.id)">{{ item.name }}
              </li>
            </ul>
            <img class="more" src="../assets/img/aj/more2.png" alt="" @click="goSearch(1, activeXK)"/>
          </div>
        </div>

        <ul class="com-book">
          <li v-for="(item, index) in xkData" :key="index" @click="goBook(item)">
            <img v-if="item.imgPath" :src="item.imgPath" alt=""/>
            <img v-else-if="item.img" :src="item.img" alt=""/>
            <img v-else alt=""/>
            <!-- <p v-if="item.isInner==0">
                {{item.name}}
            </p> -->

            <p>{{ item.name }}</p>
          </li>

        </ul>
      </div>

      <div class="organizer wid1200">
        <div class="com-title2">
          <h5>主办单位导览</h5>
          <div>
            <ul>
              <li v-if="ind<3" :class="[activeZB==item? 'active' : '']" v-for="(item, ind) in zbFilter"
                  :key="ind" @click="switchZB(item)">{{ item }}
              </li>
            </ul>
            <img class="more" src="../assets/img/aj/more2.png" alt="" @click="goSearch(2, activeZB)"/>
          </div>
        </div>

        <ul class="com-book">
          <li v-for="(item, index) in zbData" :key="index" @click="goBook(item)">
            <img v-if="item.imgPath" :src="item.imgPath" alt=""/>
            <img v-else-if="item.img" :src="item.img" alt=""/>
            <img v-else alt="">
            <!-- <p v-if="item.isInner==0">
                {{item.name}}
            </p> -->

            <p>{{ item.name }}</p>
          </li>

        </ul>
      </div>
    </div>

    <!-- Publisher -->

    <div class="bg1">
      <div class="citation wid1200">
        <h5 class="com-title1">
          <span>出版单位导览</span>
        </h5>
        <div class="type-list">
          <ul>
            <li :class="[activeCB==item? 'active' : '']" v-for="(item, ind) in cbFilter" :key="ind"
                @click="switchCB(item)">{{ item }}
            </li>
          </ul>
          <img class="more" src="../assets/img/aj/more2.png" alt="" @click="goSearch(3, activeCB)"/>
        </div>
        <ul class="com-book">
          <li v-for="(item, index) in cbData" :key="index" @click="goBook(item)">
            <img v-if="item.imgPath" :src="item.imgPath" alt=""/>
            <img v-else-if="item.img" :src="item.img" alt=""/>
            <img v-else alt="">
            <!-- <p v-if="item.isInner==0">
                {{item.name}}
            </p> -->

            <p>{{ item.name }}</p>
          </li>

        </ul>

      </div>
    </div>

    <div class="bg2">
      <div class="subject wid1200">
        <div class="com-title2">
          <h5>创刊年代导览</h5>
          <div>
            <ul>
              <li :class="[activeND==item.name? 'active' : '']" v-for="(item, ind) in ndFilter" :key="ind"
                  @click="switchND(item.name)">{{ item.name }}
              </li>
              <!-- <li :class="[activeND==item.id? 'active' : '']" v-for="(item, ind) in ndFilter" :key="ind"
              @click="switchND(item.id)" v-if="ind==4">{{item.year1}}</li> -->
            </ul>
            <img class="more" src="../assets/img/aj/more2.png" alt="" @click="goSearch(4, activeND)"/>
          </div>
        </div>

        <ul class="com-book">
          <li v-for="(item, index) in ndData" :key="index" @click="goBook(item)">
            <img v-if="item.imgPath" :src="item.imgPath" alt=""/>
            <img v-else-if="item.img" :src="item.img" alt=""/>
            <img v-else alt="">
            <!-- <p v-if="item.isInner==0">
                {{item.name}}
            </p> -->

            <p>{{ item.name }}</p>
          </li>

        </ul>
      </div>

      <div class="organizer wid1200">
        <div class="com-title2">
          <h5>出版周期导览</h5>
          <div>
            <ul>
              <li :class="[activeZhQ==item? 'active' : '']" v-for="(item, ind) in zhqFilter" :key="ind"
                  @click="switchZhQ(item)">{{ item }}
              </li>
            </ul>
            <img class="more" src="../assets/img/aj/more2.png" alt="" @click="goSearch(5, activeZhQ)"/>
          </div>
        </div>

        <ul class="com-book">
          <li v-for="(item, index) in zhqData" :key="index" @click="goBook(item)">
            <img v-if="item.imgPath" :src="item.imgPath" alt=""/>
            <img v-else-if="item.img" :src="item.img" alt=""/>
            <img v-else alt="">
            <!-- <p v-if="item.isInner==0">
                {{item.name}}
            </p> -->

            <p>{{ item.name }}</p>
          </li>

        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import HeaderAnnualMeeting from '../components/HeaderAnnualMeeting'
import HeaderAJ from '../components/HeaderAJ'
import BreadCrumb from '../components/BreadCrumb'

// import TopBanner from '../components/TopBanner'
import ComArticle from '../components/ComArticle'
import ComBook from '../components/ComBook'
import ResourceDiscipline from '../components/ChartResourceDiscipline'
import WordCloud from '../components/ChartWordCloud'
import OutputTime from '../components/ChartOutputTime'
import FooterOa from '../components/FooterOa'
import TopBanner from '../components/ComBannerOa'

import img1 from '../assets/img/book.png'

var curDate = new Date()
export default {
  name: '',
  components: {
    HeaderAnnualMeeting,
    HeaderAJ,
    BreadCrumb,
    TopBanner,
    ComArticle,
    ComBook,
    ResourceDiscipline,
    WordCloud,
    OutputTime,
    FooterOa
  },
  data() {
    return {
      pN: '',
      pageName: '人文社会科学集刊全品种目录',
      subjectId: '',
      pageNum: 1,
      pageSize: 10,

      xkData: [],
      zbData: [],
      cbData: [],
      xkFilter: [], //学科导览
      zbFilter: [], //主办单位
      cbFilter: [], //出版单位
      activeXK: '',
      activeZB: '',
      activeCB: '',
      ndData: [],
      ndFilter: [
        {
          id: 1,
          year0: '2020',
          year1: curDate.getFullYear(),
          name: '2020-2022',
        },
        {
          id: 2,
          year0: '2010',
          year1: '2019',
          name: '2010-2019',
        },
        {
          id: 3,
          year0: '2000',
          year1: '2009',
          name: '2000-2009',
        },
        {
          id: 4,
          year0: '1990',
          year1: '1999',
          name: '1990-1999',
        },
        {
          id: 5,
          year0: '1900',
          year1: '1989',
          name: '1990年以前',
        },
      ],
      activeND: '2020-2022',
      zhqData: [],
      zhqFilter: ['一年一辑', '一年两辑', '一年三辑', '一年四辑', '其他'],//'三年一辑', '两年一辑',
      activeZhQ: '一年一辑',

      dlData: [],

      excllentYear: [],
      excllentList: [],
      currentOffset: 0,
      windowSize: 6,
      paginationFactor: 185
    }
  },
  mounted() {
    // window.scrollTo(0, 0)
    // console.log(11111)
    // this.scrollTop()
  },
  computed: {
    classOption: function () {
      return {
        step: 0.5,
        limitMoveNum: 4
      }
    },
    atEndOfList() {
      return this.currentOffset <= this.paginationFactor * -1 * (this.dlData.length - this.windowSize)
    },
    atHeadOfList() {
      return this.currentOffset === 0
    }
  },
  methods: {
    moveCarousel(direction) {
      // Find a more elegant way to express the :style. consider using props to make it truly generic
      if (direction === 1 && !this.atEndOfList) {
        this.currentOffset -= this.paginationFactor
      } else if (direction === -1 && !this.atHeadOfList) {
        this.currentOffset += this.paginationFactor
      }
    },
    scrollTop(selector) {
      let element = (selector && document.querySelector(selector)) || window
      element.scrollTo(0, 0)
    },
    goSearch(num, val) {
      if (num == 4) {
        var curX = ''
        this.ndFilter.forEach((e) => {
          if (e.name == val) {
            curX = e.year0
          }
        })
        this.$router.push({path: '/ajS/a', query: {filterNum: num, filterVal: curX, pN: encodeURI(this.pageName)}})
      } else {
        this.$router.push({path: '/ajS/a', query: {filterNum: num, filterVal: val, pN: encodeURI(this.pageName)}})
      }
    },
    switchXK(item) {
      if (this.activeXK == item) {
        return
      } else {
        this.activeXK = item
        this.getBook('xk')
      }
    },
    switchZB(item) {
      if (this.activeZB == item) {
        return
      } else {
        this.activeZB = item
        this.getBook('zb')
      }
    },
    switchCB(item) {
      if (this.activeCB == item) {
        return
      } else {
        this.activeCB = item
        this.getBook('cb')
      }
    },
    switchND(item) {
      if (this.activeND == item) {
        return
      } else {
        this.activeND = item
        this.getBook('nd')
      }
    },
    switchZhQ(item) {
      if (this.activeZhQ == item) {
        return
      } else {
        this.activeZhQ = item
        this.getBook('zhq')
      }
    },
    goBook(item) {
      if (item.collectedPapersType == 1) {
        //社外
        this.$router.push({path: '/ajC/a', query: {id: item.id, pN: this.pageName}})
      } else {
        //社内
        this.$router.push({path: '/c/c', query: {id: item.id, pN: this.pageName}})
      }
    },
    getFilterData() {
      let _this = this
      this.$http({
        method: 'get',
        url: '/admin/api/index/catalogFilter',
      }).then((res) => {
        if (res.data.code == 0) {
          let data = res.data.data
          _this.xkFilter = data.xkClassifyList
          _this.zbFilter = data.researchInsList
          _this.cbFilter = data.publisherList

          _this.activeXK = data.xkClassifyList[0].id
          _this.activeZB = data.researchInsList[0]
          _this.activeCB = data.publisherList[0]

          _this.getBook('dl')
          _this.getBook('xk')
          _this.getBook('zb')
          _this.getBook('cb')
          _this.getBook('nd')
          _this.getBook('zhq')
        }
      })
    },
    getBook(val) {
      let _this = this

      var x = ''
      var y = ''
      if (val == 'nd') {
        if (this.activeND) {
          this.ndFilter.forEach((e) => {
            if (this.activeND == e.name) {
              x = e.year0 + '-01-01'
              y = e.year1 + '-12-31'
            }
          })
        }
      }


      this.$http({
        method: 'post',
        url: '/admin/api/search/find',
        data: {
          type: 1, // 检索类型：1、全文 2、集刊名称  5、主办单位 6、出版单位  默认全文
          searchKeyword: '', //检索词
          cssci: val == 'dl' ? '1' : '', //cssci 0不是 1是
          classifyId: val == 'xk' ? this.activeXK : '', // 学科id
          cycle: val == 'zhq' ? this.activeZhQ : '', // 出版周期
          insNameList: val == 'zb' ? this.activeZB : '', // 主办单位
          publisher: val == 'cb' ? this.activeCB : '', //出版单位
          collectedPapersType: 'ALL', // 集刊类型 ALL:全部 0:社内(默认) 1:社外
          resourceType: 'COLLECTED_PAPERS', //  // 固定字段， 集刊和 外部集刊
          onlineTimeStartDate: x, // 创刊时间开始时间
          onlineTimeEndDate: y, // 创刊时间结束时间
          pageNo: 1, //页码
          pageSize: val == 'dl' ? 100 : 6, // 每页条数
        },
      }).then((res) => {
        if (res.data.code == 0) {
          switch (val) {
            case 'dl':
              _this.dlData = res.data.data.rows
              break
            case 'xk':
              _this.xkData = res.data.data.rows
              break
            case 'zb':
              _this.zbData = res.data.data.rows
              break
            case 'cb':
              _this.cbData = res.data.data.rows
              break
            case 'nd':
              _this.ndData = res.data.data.rows
              break
            case 'zhq':
              _this.zhqData = res.data.data.rows
              break
          }
        }
      })
    },
  },
  created() {
    if (this.$route.query.pN) {
      this.pN = decodeURI(this.$route.query.pN)
    } else {
      this.pN = ''
    }

    this.getFilterData()
  },
}
</script>


<style lang="scss" scoped>
.aj-page {
  .bg1 {
    background: #eff4f8;
  }

  .bg2 {
    background: #fff;
  }

  .com-title1 {
    height: 35px;
    line-height: 35px;
    position: relative;
    display: flex;
    justify-content: center;

    span {
      display: inline-block;
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      background: #eff4f8;
      padding: 0 13px;
      position: relative;
    }

    span::before {
      content: '';
      width: 6px;
      height: 20px;
      background: #c30d23;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    span::after {
      content: '';
      width: 6px;
      height: 20px;
      background: #c30d23;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .com-title1::before {
    content: '';
    width: 100%;
    height: 1px;
    background: #c30d23;
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .com-title2 {
    display: flex;
    justify-content: space-between;
    background: #f5fbff;
    height: 44px;
    padding-left: 13px;
    padding-right: 10px;

    h5 {
      font-weight: 500;
      font-size: 22px;
      color: #2b221b;
      line-height: 44px;
      padding-left: 16px;
      position: relative;
    }

    h5::before {
      content: '';
      width: 6px;
      height: 18px;
      background: #c30d23;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    div {
      display: flex;
      align-items: center;

      ul {
        display: flex;

        li {
          line-height: 44px;
          color: #0d1020;
          font-size: 16px;
          font-weight: bold;
          padding: 0 20px;
          position: relative;
          cursor: pointer;
        }

        li::after {
          content: '';
          width: 1px;
          height: 11px;
          background: #777777;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        li:last-child::after {
          display: none;
        }

        li.active {
          color: #c30d23;
        }
      }

      img.more {
        display: inline-block;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }

      a {
        cursor: pointer;
      }
    }
  }

  ul.com-book {
    position: relative;
    margin-top: 40px;
    z-index: 0;
    display: flex;
    min-height: 366px;
    //flex-wrap: wrap;
    li {
      margin-right: 24px;
      width: 180px;
      margin-bottom: 15px;

      img {
        width: 180px;
        height: 260px;
        margin-bottom: 75px;
        cursor: pointer;
        box-shadow: 0px 15px 18px -15px #4f4a4a;
      }

      p {
        color: #0d1020;
        font-size: 16px;
        width: 180px;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    li:last-child {
      margin-right: 0;
    }
  }

  ul.com-book::after {
    content: '';
    position: absolute;
    width: 1380px;
    height: 116px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: -2;
    background: url(../assets/img/oa/img-bookshelf.png);
    background-size: 100% 100%;
  }

  .citation {
    padding-top: 60px;
    padding-bottom: 50px;

    .type-list {
      background: #fff;
      padding: 25px 10px 16px;
      display: flex;
      justify-content: space-between;

      ul {
        display: flex;

        li {
          padding: 0 12px;
          height: 32px;
          line-height: 32px;
          background: #f8fafb;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          margin-right: 24px;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          border: 1px solid #dee6e9;
          cursor: pointer;
        }

        li.active {
          background: #c30d23;
          color: #fff;
        }
      }

      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
        margin-top: 2px;
      }
    }


    .swiper-book .card-carousel-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0 20px;
      color: #666a73;
    }

    .swiper-book .card-carousel {
      display: flex;
      justify-content: center;
      // width: 958px;
      width: 1078px; //708px;
    }

    .swiper-book .card-carousel--overflow-container {
      overflow: hidden;
    }

    .swiper-book .card-carousel--nav__left,
    .card-carousel--nav__right {
      display: inline-block;
      width: 30px;
      height: 30px;
      padding: 10px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-top: 4px solid #bd1a2d;
      border-right: 4px solid #bd1a2d;
      cursor: pointer;
      margin: 0px 12px;
      transition: transform 150ms linear;
    }

    .swiper-book .card-carousel--nav__left[disabled],
    .card-carousel--nav__right[disabled] {
      opacity: 0.2;
      border-color: black;
    }

    .swiper-book .card-carousel--nav__left {
      transform: rotate(-135deg);
    }

    .swiper-book .card-carousel--nav__left:active {
      transform: rotate(-135deg) scale(0.9);
    }

    .swiper-book .card-carousel--nav__right {
      transform: rotate(45deg);
    }

    .swiper-book .card-carousel--nav__right:active {
      transform: rotate(45deg) scale(0.9);
    }

    .swiper-book .card-carousel-cards {
      display: flex;
      transition: transform 150ms ease-out;
      transform: translatex(0px);
    }

    .swiper-book .card-carousel-cards .card-carousel--card {
      margin: 0 16px;
      cursor: pointer;
      //background-color: #fff;
      z-index: 3;
      margin-bottom: 2px;
      width: 153px;
      height: 254px; //222px;
    }

    .swiper-book .card-carousel-cards .card-carousel--card:first-child {
      margin-left: 0;
    }

    .swiper-book .card-carousel-cards .card-carousel--card:last-child {
      margin-right: 0;
    }

    .swiper-book .card-carousel-cards .card-carousel--card img {
      vertical-align: bottom;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      // border-radius: 4px;
      transition: opacity 150ms linear;
      user-select: none;
      // width: 153px;
      // border: 1px  solid rgba(0, 0, 0, 0.1);
      width: 153px;
      height: 222px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 6px 1px rgba(15, 11, 5, 0.2);
    }

    .swiper-book .card-carousel-cards .card-carousel--card .title-spell {
      color: #0d1020;
      font-size: 16px;
      display: block;
      width: 153px;
      line-height: 42px;
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .swiper-book .card-carousel-cards .card-carousel--card img:hover {
      opacity: 0.5;
    }

    .swiper-book .card-carousel-cards .card-carousel--card--footer {
      border-top: 0;
      padding: 7px 15px;
    }

    .swiper-book .card-carousel-cards .card-carousel--card--footer p {
      padding: 3px 0;
      margin: 0;
      margin-bottom: 2px;
      font-size: 19px;
      font-weight: 500;
      color: #2c3e50;
      user-select: none;
    }

    .swiper-book .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2) {
      font-size: 12px;
      font-weight: 300;
      padding: 6px;
      background: rgba(40, 44, 53, 0.06);
      display: inline-block;
      position: relative;
      margin-left: 4px;
      color: #666a73;
    }

    .swiper-book .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):before {
      content: '';
      float: left;
      position: absolute;
      top: 0;
      left: -12px;
      width: 0;
      height: 0;
      border-color: transparent rgba(40, 44, 53, 0.06) transparent transparent;
      border-style: solid;
      border-width: 12px 12px 12px 0;
    }

    .swiper-book .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):after {
      content: '';
      position: absolute;
      top: 10px;
      left: -1px;
      float: left;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background: white;
      box-shadow: -0px -0px 0px #004977;
    }
  }

  .subject {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .organizer {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.pagination-wrap {
  display: flex;
  align-items: center;

  .total-page {
    font-size: 14px;
    color: #333 !important;
    margin-right: 4px;
  }
}
</style>

